// Note - this is the large video at the top of video article pages, not the flexible content video

import React, { useState } from "react";

import { Icon, IconType } from "~/components/elements/Icon";
import Image from "~/components/elements/Image";
import ModalVideo from "react-modal-video";
import "react-modal-video/scss/modal-video.scss";

const ArticleVideo = ({ article }) => {
	const [isModalOpen, setIsModalOpen] = useState(false);

	if (typeof window === "undefined" || !article?.video?.video) return null;

	return (
		<>
			<div className="article-video group relative cursor-pointer" onClick={() => setIsModalOpen(true)}>
				{/* Placeholder image */}
				<Image image={article.video.image} />

				{/* Play icon overlay */}
				<div className="absolute inset-0 z-10 flex flex-col justify-center bg-dark-purple bg-opacity-70">
					<div className="ml-8 flex items-center md:ml-32">
						<div className="relative z-10 flex h-28 w-28 items-center justify-center rounded-full bg-white text-dark-purple">
							<div className="absolute inset-0 h-28 w-28 rounded-full bg-white transition-transform duration-300 ease-in-out group-hover:scale-110" />
							<div className="fw-icon absolute left-1/2 top-1/2 ml-1 w-7 -translate-x-1/2 -translate-y-1/2 transform">
								<Icon type={IconType.Play} />
							</div>
						</div>
						<div className="ml-6 text-[21px]">Play video</div>
					</div>
				</div>
			</div>

			{/* Modal */}
			<ModalVideo channel="custom" autoplay={1} isOpen={isModalOpen} url={article?.video?.video?.localFile?.publicURL} onClose={() => setIsModalOpen(false)} />
		</>
	);
};

export default ArticleVideo;
