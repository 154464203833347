import React from "react";

const DefaultHero = ({ title }) => {
	return (
		<div className="max-w-[1024px] pb-12 pt-12 md:pb-48">
			<h1 className="text-h1-large !font-body">{title}</h1>
		</div>
	);
};

export default DefaultHero;
