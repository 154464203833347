import React from "react";
import { Button, ButtonType } from "~/components/elements/buttons/Button";
import { IconType } from "~/components/elements/Icon";
import Image from "~/components/elements/Image";

const WhitepapersPodcastsHero = (props) => {
	const { title, article } = props;

	const fileLink = article?.whitepaper?.file?.localFile?.publicURL;

	return (
		<div className="mb-24 grid grid-cols-12">
			<div className="col-span-12 lg:col-span-8">
				{/* Title */}
				<h1 className="text-h1-large mb-10 !font-body">{title}</h1>

				{!!fileLink && <Button link={{ url: fileLink, title: "Download" }} icon={IconType.ArrowRightDown} type={ButtonType.Button} noGatsbyLink newTab />}
			</div>

			{/* Image */}
			<div className="col-span-12 mt-8 lg:col-span-3 lg:col-start-10 lg:mt-0">
				<Image image={article.image} />
			</div>
		</div>
	);
};

export default WhitepapersPodcastsHero;
