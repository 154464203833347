import { graphql, Link } from "gatsby";
import React, { useState } from "react";
import Layout from "~/components/Layout";

import moment from "moment";
import FlexibleContent from "~/components/FlexibleContent";
import { Icon, IconType } from "~/components/elements/Icon";
import {
	WpPage_Page_FlexibleContent,
	// WpPost,
	// WpPostFieldsEnum,
} from "~/_generated/types";
import Switcher from "~/components/elements/Switcher";
import { useSearch } from "~/hooks/useSearch";
import useBlogPosts from "~/hooks/useBlogPosts";
import SearchResults from "~/components/global/SearchResults";
import Image from "~/components/elements/Image";
import SocialSharing from "~/components/elements/SocialSharing";
import Seo from "gatsby-plugin-wpgraphql-seo";
import "react-modal-video/scss/modal-video.scss";
import WhitepapersPodcastsHero from "./article/WhitepapersPodcastsHero";
import DefaultHero from "./article/DefaultHero";
import ArticleVideo from "./article/ArticleVideo";

const articleTypeMap = {
	article: "Article",
	video: "Video",
	podcast: "Podcast",
	whitepaper: "Whitepaper",
	casestudy: "Case Study",
};

export default function articlePage({
	data: { wpPage, wpPost, wp, siteSearchIndex },
}: {
	data: { wpPage: { page: { flexibleContent: WpPage_Page_FlexibleContent } } };
}) {
	const [query, setQuery] = useState("");

	const { allWpPost } = useBlogPosts();

	const results = useSearch(siteSearchIndex.index, query);

	const [light, setLight] = useState(false);

	const resourceResults = results.filter(({ nodeType }) => nodeType === "Post").map(({ slug }) => allWpPost.nodes.find((post) => post.slug === slug));

	return (
		<Layout wp={wp} activeSearchQuery={query} setQuery={setQuery} showResourcesHeader resourcesHeaderLight={light}>
			<Seo post={wpPost} title={wpPost.title} />

			{query.trim().length > 0 ? (
				<SearchResults results={resourceResults} query={query} />
			) : (
				<div
					className={`pt-[149px] md:pt-[224px] ${light ? "bg-purple-4 text-black" : "bg-dark-purple text-white"} transition-colors duration-300 ease-in-out`}
				>
					<div className="container">
						{/* Back link and darklight mode toggle */}
						<div className="flex py-6 md:py-16">
							<Link to="/resources" className="flex items-center space-x-2.5 transition-opacity duration-300 ease-in-out hover:opacity-60">
								<Icon type={IconType.ArrowLeft} />
								<span>Back to Resources</span>
							</Link>
							<div className="ml-auto">
								<Switcher active={light} setActive={setLight} showIcons={true} light={light} />
							</div>
						</div>

						{/* Hero */}
						{["podcast", "whitepaper"]?.includes(wpPost.article.type) ? (
							<WhitepapersPodcastsHero title={wpPost.title} article={wpPost.article} />
						) : (
							<DefaultHero title={wpPost.title} />
						)}

						<div>
							<div className="flex items-center">
								<div className="flex items-center space-x-8">
									{/* Article type */}
									<div className="text-[16px] capitalize md:text-[26px]">{articleTypeMap?.[wpPost.article.type]}</div>

									{/* Read/watch time */}
									<div className="flex items-center space-x-2.5">
										{wpPost.article.readwatchlistenTime && (
											<>
												<div className="h-4 w-4 text-current">
													<Icon type={"clock"} />
												</div>
												<span>{wpPost.article.readwatchlistenTime}</span>
											</>
										)}
									</div>
								</div>

								{/* Date */}
								<div className="ml-auto text-[16px] md:text-[26px]">
									<time className="inline-block">{moment(wpPost.date).format("DD-MM-YY")}</time>
								</div>
							</div>

							{/* Border */}
							<div className="pt-8">
								<div className="border-b border-b-purple" />
							</div>
						</div>

						{/* Main content */}
						<Content wpPost={wpPost} />
					</div>
				</div>
			)}
		</Layout>
	);
}

export const Content = ({ wpPost }) => (
	<div className="mt-8 md:mt-[150px] md:pb-20">
		{/* Video */}
		{wpPost.article.type === "video" && (
			<div className="mb-12 w-full md:mb-24">
				<ArticleVideo article={wpPost.article} />
			</div>
		)}

		<div className="flex flex-col items-start lg:flex-row">
			{/* Aside */}
			<div className="w-full lg:sticky lg:top-48 lg:max-w-[30%] xl:max-w-[432px]">
				<div className="space-y-10">
					{/* Summary */}
					<div className="text-[32px] leading-[1.1]">{wpPost.article.summary}</div>

					<div className="flex flex-col space-y-3">
						{/* Article type */}
						<div className="text-[14px] capitalize text-purple">{articleTypeMap?.[wpPost.article.type]}</div>

						{/* Article title */}
						<div className="max-w-[170px] leading-[1]">{wpPost.title}</div>

						{/* Date */}
						<div>
							<time>{moment(wpPost.date).format("DD-MM-YY")}</time>
						</div>
					</div>
				</div>

				{/* Social share links */}
				<div className="mt-6">
					<SocialSharing />
				</div>
			</div>

			{/* Right col */}
			<div className="article mt-12 w-full flex-auto overflow-hidden lg:ml-20 lg:mt-0 lg:max-w-[688px] xl:flex-shrink-0">
				{/* Article image */}
				{wpPost.article.type === "article" && (
					<div className="mb-16">
						<Image image={wpPost.article.image} />
					</div>
				)}

				{/* Content */}
				{wpPost?.content && <div className="article-content p-large prose w-full pb-16" dangerouslySetInnerHTML={{ __html: wpPost?.content }} />}

				{/* Flexible content */}
				<div className="space-y-10 md:space-y-[52px]">
					<FlexibleContent content={wpPost?.article?.flexibleContent} prefix="Post_Article_FlexibleContent_" />
				</div>
			</div>
		</div>
	</div>
);

export const pageQuery = graphql`
	query Article($id: String!) {
		wpPage(id: { eq: $id }) {
			...SEO
			...GeneratedWpPage
		}
		wpPost(id: { eq: $id }) {
			...GeneratedWpPost
		}
		wp {
			...GeneratedWp
		}
		siteSearchIndex {
			index
		}
	}
`;
