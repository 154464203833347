import React, { useState, useEffect } from "react";
import { EmailShareButton, FacebookShareButton, LinkedinShareButton, TwitterShareButton, WhatsappShareButton } from "react-share";
import { Icon, IconType } from "./Icon";

export default function StoryPreview() {
	const [pageUrl, setPageUrl] = useState("");
	useEffect(() => {
		setPageUrl(window?.location?.href);
	}, []);

	return (
		<div className="flex items-start gap-2.5 md:space-x-0 lg:flex-col">
			<FacebookShareButton url={pageUrl}>
				<div className="group flex h-[30px] w-[30px] items-center justify-center rounded-lg border border-current transition-opacity duration-300 ease-in-out hover:opacity-50">
					<div className="fw-icon h-4 w-4 text-current">
						<Icon type={IconType.Facebook} className="h-auto w-4 text-current" />
					</div>
				</div>
			</FacebookShareButton>
			<TwitterShareButton url={pageUrl}>
				<div className="group flex h-[30px] w-[30px] items-center justify-center rounded-lg border border-current transition-opacity duration-300 ease-in-out hover:opacity-50">
					<div className="fw-icon h-4 w-4 text-current">
						<Icon type={IconType.Twitter} className="h-auto w-4 text-current" />
					</div>
				</div>
			</TwitterShareButton>
			<LinkedinShareButton url={pageUrl}>
				<div className="group flex h-[30px] w-[30px] items-center justify-center rounded-lg border border-current transition-opacity duration-300 ease-in-out hover:opacity-50">
					<div className="fw-icon h-4 w-4 text-current">
						<Icon type={IconType.LinkedIn} />
					</div>
				</div>
			</LinkedinShareButton>
		</div>
	);
}
